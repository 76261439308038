<template>
    <r-e-dialog title="供应商" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="500px" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="供应商名称" prop="companyName">
                    <el-input v-model="formPublish.companyName" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="联系人" prop="linkMan">
                    <el-input v-model="formPublish.linkMan" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="formPublish.phone" :maxlength="11" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款开户行" prop="paymentBank">
                    <el-input v-model="formPublish.paymentBank" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款开户账号" prop="account">
                    <el-input v-model="formPublish.account" :minlength="16" :maxlength="19" style="width: 300px;"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="备注">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="formPublish.remark" style="width: 300px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {pubCompany} from "@/api/supplier";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "dialog-add-supplier",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    companyName:"",
                    linkMan:"",
                    phone: "",
                    paymentBank: "",
                    account: "",
                    remark: ""
                },
                rules: {
                    companyName: [{required: true, message: '请输入供应商名称', trigger: 'blur'}],
                    linkMan: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        {pattern: /[\u4e00-\u9fa5]/ , message: '联系人只能输入中文'},
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                        {pattern: /^((0\d{2,3}-\d{7,8})|(1[35789462]\d{9}))$/, message: '请输入正确的手机号码'},
                    ],
                    paymentBank:[
                        {required: false, message: '请输入开户行', trigger: 'blur'},
                        {pattern: /[\u4e00-\u9fa5]/ , message: '开户行只能输入中文'},
                    ],
                    account: [{required: false, message: '请输入开户账号', trigger: 'blur'},
                        {pattern: /^([1-9]{1})(\d{15}|\d{18})$/, message: '请输入正确的开户账号'},],

                },
            }
        },
        components: {},
        methods: {
            async openDialog() {
                let that = this;
                that.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        pubCompany(that.formPublish).then(res=>{
                            MessageSuccess('添加供应商成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err=>{
                            MessageError('添加供应商失败');
                            loading.close();
                        });
                    }
                })
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.$emit("handleSearch", false);
                this.dialogVisible = false;
            },
        },
    }
</script>

<style scoped>

</style>